import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["\n        d-flex\n        flex-column flex-md-row\n        align-items-center\n        justify-content-between\n      ", {
        'container-fluid': _ctx.footerWidthFluid,
        'container-xxl': !_ctx.footerWidthFluid,
      }])
    }, null, 2)
  ]))
}